import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

Vue.use(VueRouter);

const UUID = '[A-Fa-f0-9]{8}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{12}';

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'index',
    component: () => import('@/views/LoginView.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/LoginView.vue'),
  },
  {
    path: '/forgot_password',
    name: 'forgot_password',
    component: () => import('@/views/ForgotPasswordView.vue'),
    props: true,
  },
  {
    path: '/signout',
    name: 'signout',
    component: () => import('@/views/SignoutView.vue'),
  },
  {
    path: `/confirm_invite/:uuid(${UUID})`,
    name: 'confirm_invite',
    component: () => import('@/views/ConfirmInviteView.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;
