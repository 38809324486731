import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { createI18n } from 'vue-i18n-composable';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import * as enJSON from '../public/locales/en.json';

Vue.config.productionTip = false;
Vue.use(VueI18n);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

const i18n = createI18n({
  locale: 'en',
  messages: {
    en: enJSON,
  },
});

new Vue({
  i18n,
  router,
  render: (h) => h(App),
}).$mount('#app');
